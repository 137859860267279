<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <b-table
        :data="monitor.logs"
        :paginated="monitor.logs.length > 5"
        per-page="5"
        hoverable
        striped
        class="has-vertical-align-middle"
      >
        <template slot="empty">
          <no-results
            icon="box-open"
            title="No responses"
            message="There are no responses to show."
          />
        </template>

        <template slot-scope="props">
          <b-table-column label="Status" field="type" width="1">
            <b-tag v-if="isUp(props.row)" type="is-success">Up</b-tag>
            <b-tag v-else-if="isDown(props.row)" type="is-danger">Down</b-tag>
            <b-icon v-else icon="info-circle" />
          </b-table-column>

          <b-table-column label="Time" field="datetime" width="140">{{
            $moment.unix(props.row.datetime).format("DD/MM/YY kk:mm")
          }}</b-table-column>

          <b-table-column label="Reason" field="response_status">
            <span :class="getClass(props.row)">{{ getDetail(props.row) }}</span>
          </b-table-column>

          <b-table-column label="Duration" field="duration" width="140">
            <span v-if="props.row.type <= 2">{{
              $moment.duration(props.row.duration, "seconds").humanize()
            }}</span>
            <span v-else>--</span>
          </b-table-column>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    monitor: { required: true, type: Object }
  },
  methods: {
    isUp(log) {
      return log.type === 2;
    },
    isDown(log) {
      return log.type === 1;
    },
    showCode(log) {
      return (
        parseInt(log.reason.code) >= 200 && parseInt(log.reason.code) < 600
      );
    },
    getClass(log) {
      if (this.isDown(log)) return "has-text-danger";
      else if (this.isUp(log)) return "has-text-success";
      return "has-text-dark";
    },
    getDetail(log) {
      //prettier-ignore
      const code = this.showCode(log) ? `(${log.reason.code})`: '';
      return `${log.reason.detail} ${code}`;
    }
  }
};
</script>
